<template>
  <div class="elv-apply-project-form">
    <el-scrollbar
      class="elv-apply-project-form-scroller"
      view-class="elv-apply-project-form__view"
      max-height="calc(100vh - 300px)"
    >
      <el-form ref="projectFormRef" :model="projectForm" :rules="rules" label-position="top">
        <el-form-item :label="t('report.entityName')" prop="name">
          <el-input v-model="projectForm.name" minlength="1" maxlength="64" placeholder="" />
        </el-form-item>
        <el-form-item :label="t('report.entityLogo')">
          <ImageUpload v-model="projectForm.logo" />
        </el-form-item>
        <el-form-item :label="t('report.countryRegion')" prop="region">
          <el-select
            v-model="projectForm.region"
            filterable
            :disabled="
              props.currentEntityData?.region === 'Singapore' && projectForm.enableGst && props.model === 'edit'
            "
            popper-class="elv-apply-project-form-popper"
            :suffix-icon="SelectSuffixIcon"
            @change="onChangeRegion"
          >
            <el-option
              v-for="(item, index) in countryOptions"
              :key="index"
              :label="locale === 'en' ? item.name_en : item.name_zh"
              :value="item.name_en"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="timezone" class="elv-apply-project-form-item-flex">
          <template #label
            ><p>{{ t('common.timezone') }}</p>
          </template>
          <el-select
            v-model="projectForm.timezone"
            filterable
            default-first-option
            :disabled="props.model === 'edit'"
            popper-class="elv-apply-project-form-popper"
            :suffix-icon="SelectSuffixIcon"
          >
            <el-option
              v-for="(item, index) in timezoneList"
              :key="index"
              :label="`${item.area} (${item.timezone})`"
              :value="item.area"
            />
          </el-select>
          <EntitySettingFormDesc :model="props.model" descType="timeZone" />
        </el-form-item>
        <el-form-item
          v-if="projectForm.region === 'Singapore'"
          :label="t('report.GSTSetting')"
          class="elv-apply-project-form-item-flex"
        >
          <el-checkbox
            v-model="projectForm.enableGst"
            :disabled="props.currentEntityData?.region === 'Singapore' && props.model === 'edit'"
            class="elv-apply-project-form-checkbox"
            >{{ t('report.enableGST') }}</el-checkbox
          >
          <EntitySettingFormDesc :model="props.model" descType="gstSetting" />
        </el-form-item>
        <el-form-item
          :label="t('title.accountingStartDate')"
          prop="startingDate"
          class="elv-apply-project-form-item-flex date"
        >
          <template #label
            ><p>{{ t('title.accountingStartDate') }}</p>
          </template>
          <el-date-picker
            ref="datePickerStartPopoverRef"
            v-model="projectForm.startingDate"
            :disabled="props.model === 'edit'"
            placement="bottom-start"
            placeholder="Unspecified"
            popper-class="elv-apply-project-form-date-popper"
            type="date"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            clearable
          />
          <EntitySettingFormDesc :model="props.model" descType="accountingStartDate" />
        </el-form-item>
        <el-form-item
          :label="t('report.functionalCurrency')"
          prop="defaultCurrency"
          class="elv-apply-project-form-item-flex"
        >
          <template #label
            ><p>{{ t('report.functionalCurrency') }}</p>
          </template>
          <el-select
            v-model="projectForm.defaultCurrency"
            popper-class="elv-apply-project-form-popper"
            filterable
            remote
            :disabled="props.model === 'edit'"
            :suffix-icon="SelectSuffixIcon"
            reserve-keyword
            class="elv-apply-project-form-item__currency"
            :loading="currencyLoading"
            :remote-method="remoteCurrencyMethod"
            @visible-change="onVisibleCurrencyChange"
          >
            <el-option
              v-for="currency in currenciesData"
              :key="currency.underlyingCurrencyId"
              :label="currency.name"
              :value="currency.symbol"
            />
            <template #prefix>
              <SelectSuffixIcon :style="{ transform: currencyArrow }" />
            </template>
          </el-select>
          <EntitySettingFormDesc :model="props.model" descType="functionalCurrency" />
        </el-form-item>
        <el-form-item :label="t('report.preferredCurrencyUnit')" prop="unit" class="elv-apply-project-form-item-flex">
          <el-select
            v-model="projectForm.unit"
            :suffix-icon="SelectSuffixIcon"
            popper-class="elv-apply-project-form-popper"
          >
            <el-option
              v-for="(item, index) in unitOptions"
              :key="index"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
          <EntitySettingFormDesc :model="props.model" descType="preferredCurrencyUnit" />
        </el-form-item>
      </el-form>
    </el-scrollbar>

    <elv-button
      :width="100"
      height="44"
      round
      type="primary"
      :loading="submitLoading"
      :disabled="disabledButton"
      class="elv-apply-trial-submit"
      :class="{ 'is-center': props.fromType === 'entity' }"
      @click="onSubmitSave"
      >{{ buttonContent }}</elv-button
    >
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import ConfigApi from '@/api/ConfigApi'
import EntityApi from '@/api/EntityApi'
import { ElMessage } from 'element-plus'
import countryList from '@/config/country'
import ProjectApi from '@/api/ProjectApi'
import { unitOptions } from '@/config/index'
import timezoneList from '@/config/timezone'
import { transformI18n } from '@/i18n/index'
import { CurrencyItemType } from '#/ReportsTypes'
import SelectSuffixIcon from './SelectSuffixIcon.vue'
import ImageUpload from '@/components/ImageUpload.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'
import type { FormInstance, FormRules } from 'element-plus'
import { isEmpty, sortBy, uniqBy, cloneDeep } from 'lodash-es'
import EntitySettingFormDesc from './EntitySettingFormDesc.vue'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'

const props = defineProps({
  model: {
    type: String,
    default: 'add' // add | edit
  },
  currentEntityData: {
    type: Object,
    default: () => {}
  },
  fromType: {
    type: String,
    default: 'project' // project | entity
  }
})

const route = useRoute()
const router = useRouter()
const { t, locale } = useI18n()
const globalStore = useGlobalStore()
const entityStore = useEntityStore()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()
const userGlobalStore = useUserGlobalStore()

const submitLoading = ref(false)
const currencyLoading = ref(false)
const projectFormRef = ref<FormInstance>()
const currencyArrow = ref('rotate(90deg)')
const currenciesData = ref<CurrencyItemType[]>([])
const currencyList = ref<CurrencyItemType[]>([
  {
    name: 'US Dollar',
    showSymbol: 'USD',
    symbol: 'USD',
    logo: 'https://static.elven.com/financial/legal/usd.png',
    type: 'FIAT',
    underlyingCurrencyId: 'usd',
    sign: '$'
  },
  {
    name: 'Singapore Dollar',
    symbol: 'SGD',
    showSymbol: 'SGD',
    logo: 'https://static.elven.com/financial/legal/sgd.png',
    type: 'FIAT',
    underlyingCurrencyId: 'sgd',
    sign: 'S$'
  }
])

const projectForm = reactive({
  name: '',
  logo: 'https://static.elven.com/project/10d82a51bfdf07f38b89cf0daf2d29c2.png',
  fairValueCostBasisMethodology: 'FIFO',
  region: '',
  defaultCurrency: 'USD',
  unit: 'thousands',
  enableGst: false,
  timezone: 'UTC',
  startingDate: ''
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'name is required'
  },
  defaultCurrency: {
    required: true,
    trigger: 'blur',
    message: 'Default currency time is required'
  },
  timezone: {
    required: true,
    trigger: 'blur',
    message: 'Timezone is required'
  },
  region: {
    required: true,
    trigger: 'blur',
    message: 'Country/Region is required'
  },
  fairValueCostBasisMethodology: {
    required: true,
    trigger: 'blur',
    message: 'Cost basis methodology is required'
  },
  unit: {
    required: true,
    trigger: 'blur',
    message: 'Preferred currency unit is required'
  }
})

const buttonContent = computed(() => {
  if (props.model === 'edit') t('button.saveChanges')
  return t('button.save')
})

const countryOptions = computed(() => {
  if (locale.value === 'zh') return sortBy(countryList, [(o) => o.name_zh])
  return sortBy(countryList, [(o) => o.name_en])
})

const projectId = computed(() => {
  return String(route.params.projectId)
})

const disabledButton = computed(() => {
  return !projectForm.name || !projectForm.unit
})

const emit = defineEmits(['onCheckEntityDialog'])

/**
 * @description: 选择国家/地区
 */
const onChangeRegion = () => {
  projectForm.enableGst = false
}

/**
 * @description: 展开/收起Currency
 * @param {boolean} visible
 */
const onVisibleCurrencyChange = (visible: boolean) => {
  currencyArrow.value = visible ? 'rotate(270deg)' : 'rotate(90deg)'
}

/**
 * @description: 远程搜索Currency
 * @param {string} query
 */
const remoteCurrencyMethod = async (query: string) => {
  if (query) {
    try {
      currencyLoading.value = true
      const params = {
        recommend: false,
        name: query,
        type: 'FIAT',
        entityId: 0
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
    } catch (error) {
      console.log(error)
      currenciesData.value = []
    } finally {
      currencyLoading.value = false
    }
  } else {
    currenciesData.value = currencyList.value
  }
}

/**
 * @description: 创建Entity
 */
const onCreateEntity = async () => {
  try {
    let entityId: string = ''
    const params: any = { ...projectForm }
    if (!projectForm.startingDate) {
      delete params.startingDate
    }
    const res: any = await ProjectApi.createProjectEntity(projectId.value, params)
    entityId = res.data.entityId
    projectStore.fetchProjectDetail(projectId.value)
    ElMessage.success(t('message.addEntitySuccess'))
    emit('onCheckEntityDialog')
    await Promise.all([globalStore.fetchProjectList(), userGlobalStore.userInit()])
    router.push({ name: 'entity', params: { entityId } })
    menuGlobalStore.menuListInit()
    emitter.emit('onPushOpenCollapse', {
      type: props.fromType,
      projectId: res.data?.projectId,
      entityId,
      name: res.data.name
    })
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

/**
 * @description: 更新Entity
 */
const onUpdateEntity = async () => {
  try {
    const params: any = { ...projectForm }
    delete params.timezone
    delete params.startingDate
    await EntityApi.editEntityDetail(props.currentEntityData.entityId, params)
    ElMessage.success(t('message.editSuccess'))
    submitLoading.value = false
    entityStore.fetchEntityDetail(props.currentEntityData.entityId)
    if (props.fromType === 'entity') {
      emit('onCheckEntityDialog')
      projectStore.fetchEntityList(projectId.value)
    }
    await globalStore.fetchProjectList()
    menuGlobalStore.menuListInit()
    emitter.emit('onPushOpenCollapse', {
      type: props.fromType,
      projectId: props.currentEntityData?.projectId,
      name: projectForm.name,
      isEdit: true
    })
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    submitLoading.value = false
  }
}

/**
 * @description: 提交保存
 */
const onSubmitSave = async () => {
  return projectFormRef.value?.validate(async (valid: boolean) => {
    if (valid) {
      submitLoading.value = true
      if (props.model === 'add') {
        await onCreateEntity()
      } else {
        await onUpdateEntity()
      }
    }
  })
}

watch(
  () => props.currentEntityData,
  async (val: any) => {
    if (!isEmpty(props.currentEntityData)) {
      projectFormRef.value?.resetFields()
      projectForm.name = val.name
      projectForm.logo = val.logo
      projectForm.fairValueCostBasisMethodology = val.fairValueCostBasisMethodology
      projectForm.region = val.region
      projectForm.enableGst = val.enableGst
      projectForm.defaultCurrency = val.defaultCurrency
      projectForm.timezone = val.timezone ?? 'UTC'
      projectForm.unit = val.unit
      projectForm.startingDate = val.startingDate ?? ''
      const list = [...currencyList.value, val.underlyingCurrency]
      currencyList.value = uniqBy(list, 'symbol')
      currenciesData.value = [...cloneDeep(currencyList.value)]
    } else {
      currenciesData.value = [...cloneDeep(currencyList.value)]
      projectFormRef.value?.resetFields()
      const currentProject = globalStore.projectDetail(projectId.value)
      if (currentProject) {
        projectForm.region = currentProject.region || ''
      }
    }
    const params = {
      recommend: true,
      type: 'FIAT',
      entityId: 0
    }
    if (currencyList.value.length < 4) {
      const { data } = await ConfigApi.searchCurrencyList(params)
      const list = [...currencyList.value, ...data]
      currencyList.value = uniqBy(list, 'symbol')
      currenciesData.value = [...cloneDeep(currencyList.value)]
    }
  },
  {
    immediate: true
  }
)

defineExpose({ onSubmit: onSubmitSave, disabledButton })
</script>

<style lang="scss">
.elv-apply-project-form {
  margin-top: 21px;
  padding-bottom: 20px;
  width: 100%;

  .elv-apply-project-form-scroller {
    width: 100%;

    .elv-apply-project-form__view {
      padding: 0px 24px;
      box-sizing: border-box;
      width: 100%;
    }
  }

  .el-input {
    width: 418px;
    height: 44px;
    border-radius: 4px;

    &.el-date-editor--date {
      .el-input__prefix {
        display: none;
      }
    }

    &.is-disabled {
      background: #f9fafb;

      .el-input__wrapper {
        background: #f9fafb;
        border: 1px solid #dde1e6;
        box-shadow: none;
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

        .el-input__suffix {
          svg,
          i {
            width: 20px;
            height: 20px;
            fill: #d0d4d9;
          }
        }
      }

      .el-input__inner {
        color: #0e0f11;
        -webkit-text-fill-color: #0e0f11;
      }
    }
  }

  .el-textarea:not(.is-disabled) .el-textarea__inner {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &:focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .elv-apply-project-form-checkbox {
    .el-checkbox__input,
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
    }

    .el-checkbox__inner::after {
      left: 4.5px !important;
      top: 1.5px !important;
    }

    .el-checkbox__label {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      span {
        color: #eb3a30;
        font-size: 13px;
        margin-left: 8px;
      }
    }
  }

  .el-textarea__inner {
    padding: 8px 16px;
    width: 418px;
    min-height: 100px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #0e0f11;

    &:hover,
    &:focus {
      border: 1px solid #5e85eb;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  .el-input__inner {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    color: #0e0f11;
  }

  .el-input__wrapper {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border-radius: 4px;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 1px 12px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    .el-input__suffix {
      svg,
      i {
        width: 20px;
        height: 20px;
        fill: #838d95;
      }
    }
  }

  .el-input:not(.is-disabled) .el-input__wrapper {
    &:hover {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    &.is_focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-form-item {
    margin-bottom: 16px;
    width: 652px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.is-error {
      .el-input__wrapper {
        background: #faeee6;
        border: 1px solid #7e4a15;
        box-shadow: none;
      }

      .el-input__inner {
        color: #7e4a15;
      }

      .el-form-item__error {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #7e4a15;
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
    }

    &.elv-apply-project-form-item-flex {
      background-color: #f9fafb;
      border-radius: 5px;
      padding: 8px;
      width: 100%;
      box-sizing: border-box;

      .el-form-item__label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;

        span {
          position: absolute;
          right: 0;
          color: #eb3a30;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: none;

          svg {
            margin-right: 4px;
            margin-top: 1px;
          }
        }
      }

      .el-select {
        width: 410px;
      }
    }

    &.date {
      .el-input {
        width: 410px;

        &:not(.is-disabled) {
          .el-input__inner::placeholder {
            color: #0e0f11;
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
          }
        }

        &.is-disabled .el-input__inner {
          color: #0e0f11;
          -webkit-text-fill-color: #0e0f11;

          &::placeholder {
            color: #a8abb2;
          }
        }
      }
    }

    .el-select.elv-apply-project-form-item__currency .el-select__wrapper {
      flex-direction: row-reverse;

      .el-select__suffix {
        display: none;
      }

      .el-select__prefix {
        svg {
          width: 14px;
          height: 14px;
          fill: #a8abb2;
          transition: 0.3s;
        }
      }
    }
  }

  .el-select {
    .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-input__wrapper.is-focus {
    border: 1px solid #7596eb !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
  }

  .elv-apply-trial-submit {
    margin-top: 24px;

    &.is-center {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.elv-apply-project-date-shell-popper.el-popper {
  display: none;
}

.elv-apply-project-form-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        width: 32px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}

.elv-apply-project-form-date-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    // overflow: hidden;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: fit-content;

    :has(.el-date-picker__time-header) {
      width: 237px;
    }

    :has(.el-month-table),
    :has(.el-year-table) {
      width: 322px;
    }

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      &:has(.el-month-table),
      &:has(.el-year-table) {
        width: fit-content;
      }

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          width: 31.57px;
          height: 27.8px;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;

      .el-date-picker__time-header {
        padding: 0px;
        padding-bottom: 8px;

        .el-input {
          height: 28px;

          .el-input__inner {
            color: #0e0f11;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }

      .el-time-panel {
        left: -75px;
      }
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}
</style>
